import React from 'react'
import Layout from 'src/layouts/BaseLayout'

import Header from '.././sections/header/_index'
import TabInf from '../sections/mais-que-um-banco-digital/_index'
import PorqueNaoCobramosTarifas from '.././sections/porque-nao-cobramos-tarifas/_index'

import { Wrapper } from '.././style'

import pageContext from './pageContext.json'

const ContaDigitalPF = () => {
  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Header />
        <TabInf />
        <PorqueNaoCobramosTarifas />
      </Layout>
    </Wrapper>
  )
}

export default ContaDigitalPF
